import { IntersectionInfo } from "../../game";
import { Building } from "../../game/placeBuildings";
import { ReactComponent as Meeple } from "./icons/meeple.svg";

interface Props {
  intersectionInfo: IntersectionInfo;
  zonePos?: {
    x: number;
    y: number;
  }[];
  currentClock: Building;
  selectingPassengers?: boolean;
}

const Passengers: React.FC<Props> = (props) => {
  const { intersectionInfo, zonePos, currentClock, selectingPassengers } =
    props;

  let unassignedPassengers = intersectionInfo.passengers;
  const passengers = [];
  for (let i = 0; i < intersectionInfo.buildings.length; i++) {
    const building = intersectionInfo.buildings[i]!;
    const pos = zonePos?.[i];
    if (building === currentClock && unassignedPassengers > 0 && pos) {
      unassignedPassengers -= 1;
      passengers.push(
        <g
          transform={`translate(${pos.x * 1.4}, ${
            pos.y * 1.4
          }) scale(0.025) translate(-450,-400)`}
          key={i}
        >
          <Meeple
            viewBox="-100 -100 1400 1400"
            fill={greenPassenger}
            stroke="black"
            strokeWidth={100}
          />
        </g>
      );
    }
  }

  for (let i = 0; i < unassignedPassengers; i++) {
    const r = Math.sqrt(i + 1) * 8;
    const theta = goldenRatio * i - Math.PI / 2;
    const x = r * Math.cos(theta);
    const y = r * Math.sin(theta);

    passengers.push(
      <g
        transform={`translate(${x}, ${y}) scale(0.025) translate(-450,-400)`}
        key={i + 2}
      >
        <Meeple
          viewBox="-100 -100 1400 1400"
          fill={selectingPassengers ? "red" : greenPassenger}
          stroke="black"
          strokeWidth={100}
        />
      </g>
    );
  }

  return <>{passengers}</>;
};

export default Passengers;

const goldenRatio = Math.PI * (1 + Math.sqrt(5));
const greenPassenger = "#7bca86";
