import { Ctx } from "boardgame.io";
import { BusState } from "../game";
import { getPlayerTotalScore } from "../game/gameEnd";
import { colorFromPlayerId } from "./actions/spaces";

interface Props {
  state: BusState;
  ctx: Ctx;
}

const PlayerInfo: React.FC<Props> = (props) => {
  const { state, ctx } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        borderTop: "2px solid",
        padding: "8px",
      }}
    >
      {ctx.playOrder.map((p) => {
        const playerInfo = state.playerInfo[p]!;
        return (
          <div key={p}>
            <svg height={20} width={20}>
              <rect height={20} width={20} fill={colorFromPlayerId(ctx, p)} />
            </svg>
            <p>Actions left: {playerInfo.actionMarkers}</p>
            <p>Points: {getPlayerTotalScore(state, p)}</p>
            {playerInfo.passed && <p>Passed</p>}
            {state.startPlayer === p && <p>Start player</p>}
          </div>
        );
      })}
    </div>
  );
};

export default PlayerInfo;
