const getVersionInfo = async () => {
  try {
    const fileName = "versionInfo";
    const versionJson: VersionInfo = await import(`./${fileName}.json`);
    const date = new Date(versionJson.lastModified * 1000);
    const dateParts = new Intl.DateTimeFormat("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    }).formatToParts(date);
    const year = dateParts.find((part) => part.type === "year");
    const month = dateParts.find((part) => part.type === "month");
    const day = dateParts.find((part) => part.type === "day");
    const calVer = `${year?.value ?? ""}.${month?.value ?? ""}.${
      day?.value ?? ""
    }`;
    const rev = versionJson.rev ? "-" + versionJson.rev.substring(0, 5) : "";
    return calVer + rev;
  } catch {}
};

export default getVersionInfo;

interface VersionInfo {
  lastModified: number;
  rev?: string;
}
