import { MoveFn } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io/core";
import { BusState, getMoveKind, initializeIntersection } from ".";
import staticBoard, {
  Intersection,
  IntersectionZones,
  Zone,
  zoneList,
} from "./staticBoard";

export const getUncoveredZones = (state: BusState) => {
  const { zones } = staticBoard;
  const uncoveredZones: typeof zones = {};

  for (const { intersection, zones: zoneCounts } of zoneList(zones)) {
    const currentIntersection = { ...zoneCounts };
    uncoveredZones[intersection] = currentIntersection;

    let buildingCount =
      state.intersections[intersection]?.buildings.length ?? 0;
    while (buildingCount > 0) {
      if (currentIntersection["1"] && currentIntersection["1"] > 0) {
        currentIntersection["1"] -= 1;
        buildingCount -= 1;
      } else if (currentIntersection["2"] && currentIntersection["2"] > 0) {
        currentIntersection["2"] -= 1;
        buildingCount -= 1;
      } else if (currentIntersection["3"] && currentIntersection["3"] > 0) {
        currentIntersection["3"] -= 1;
        buildingCount -= 1;
      } else if (currentIntersection["4"] && currentIntersection["4"] > 0) {
        currentIntersection["4"] -= 1;
        buildingCount -= 1;
      }
    }
  }

  return uncoveredZones;
};

const getCurrentZoneFromUncovered = (
  uncoveredZones: IntersectionZones
): Zone | null => {
  const zoneCount: { [zone in Zone]: number } = {
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
  };
  for (const { zones } of zoneList(uncoveredZones)) {
    zoneCount["1"] += zones["1"] ?? 0;
    zoneCount["2"] += zones["2"] ?? 0;
    zoneCount["3"] += zones["3"] ?? 0;
    zoneCount["4"] += zones["4"] ?? 0;
  }

  if (zoneCount["1"] > 0) return "1";
  if (zoneCount["2"] > 0) return "2";
  if (zoneCount["3"] > 0) return "3";
  if (zoneCount["4"] > 0) return "4";
  return null;
};

export const getCurrentZone = (state: BusState) => {
  const uncoveredZones = getUncoveredZones(state);
  const currentZone = getCurrentZoneFromUncovered(uncoveredZones);
  return currentZone;
};

export const getOpenBuildingIntersections = (state: BusState) => {
  const uncoveredZones = getUncoveredZones(state);
  const currentZone = getCurrentZoneFromUncovered(uncoveredZones);

  if (!currentZone) return null;

  const openBuildingIntersections = [];

  for (const { intersection, zones } of zoneList(uncoveredZones)) {
    if ((zones[currentZone] ?? 0) > 0) {
      openBuildingIntersections.push(intersection);
    }
  }

  return { openBuildingIntersections, currentZone };
};

export type Building = "house" | "office" | "pub";
export const buildings: Building[] = ["house", "office", "pub"];

export const placeBuilding: MoveFn<BusState> = (
  { G: state, ctx },
  intersection: unknown,
  building: unknown
) => {
  if (state.actionsLeft <= 0) return INVALID_MOVE;
  if (!isValidIntersection(intersection)) return INVALID_MOVE;
  if (!isValidBuilding(building)) return INVALID_MOVE;
  if (getMoveKind(state, ctx) !== "placeBuilding") return INVALID_MOVE;

  const openBuildingIntersections = getOpenBuildingIntersections(state);
  const availableIntersections =
    openBuildingIntersections?.openBuildingIntersections ?? [];

  if (!availableIntersections.includes(intersection)) return INVALID_MOVE;

  const { intersections } = state;
  if (!intersections[intersection]) {
    intersections[intersection] = initializeIntersection();
  }
  intersections[intersection]!.buildings.push(building);

  state.actionsLeft -= 1;
};

export const isValidIntersection = (
  intersection: unknown
): intersection is Intersection =>
  typeof intersection === "number" && intersection >= 0 && intersection <= 35;

const isValidBuilding = (building: unknown): building is Building =>
  typeof building === "string" && buildings.includes(building as Building);
