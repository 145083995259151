import { Building } from "../../game/placeBuildings";
import { ReactComponent as House } from "./icons/house.svg";
import { ReactComponent as Office } from "./icons/office.svg";
import { ReactComponent as Pub } from "./icons/pub.svg";

interface Props {
  building: Building;
}

const BuildingIcon: React.FC<Props> = (props) => {
  const { building } = props;
  let Component = House;

  if (building === "house") {
    Component = House;
  } else if (building === "office") {
    Component = Office;
  } else if (building === "pub") {
    Component = Pub;
  }

  return (
    <g transform="translate(-9,-9.5) scale(0.75)">
      <Component fill="white" />
    </g>
  );
};

export default BuildingIcon;

export const buildingColors: Record<Building, string> = {
  house: "#426e95",
  office: "#7d7e80",
  pub: "#ee7623",
};
