import { Ctx } from "boardgame.io";
import { BusState } from "../../game";
import { Line, LineId } from "../../game/placeLines";
import { Intersection, Zone } from "../../game/staticBoard";
import Intersections from "./Intersections";
import Lines from "./Lines";
import { ReactComponent as Vancouver } from "./vancouver.svg";
import BuildingPopup from "./BuildingPopup";
import { Building } from "../../game/placeBuildings";

interface Props {
  state: BusState;
  ctx: Ctx;
  actionableLines: LineId[] | "any";
  isActive: boolean;
  onLineAction?: (line: Line) => void;
  playerLines: LineId[][];
  intersectionAction?: IntersectionAction;
  buildingAt?: Intersection;
  chooseBuilding?: (building?: Building) => void;
}

const BusMap: React.FC<Props> = (props) => {
  const {
    state,
    ctx,
    actionableLines,
    isActive,
    onLineAction,
    playerLines,
    intersectionAction,
    buildingAt,
    chooseBuilding,
  } = props;

  return (
    <svg width="900" height="750" style={{ backgroundColor: "#b3d1e3" }}>
      <Vancouver />
      <Lines
        actionableLines={actionableLines}
        ctx={ctx}
        isActive={isActive}
        onLineAction={onLineAction}
        playerLines={playerLines}
      />
      <Intersections
        state={state}
        ctx={ctx}
        intersectionAction={intersectionAction}
        isActive={isActive}
      />
      {buildingAt !== undefined && chooseBuilding && (
        <BuildingPopup
          buildingAt={buildingAt}
          chooseBuilding={chooseBuilding}
        />
      )}
    </svg>
  );
};

export default BusMap;

export interface IntersectionAction {
  actionableIntersections: Intersection[];
  onIntersectionAction?: (intersection: Intersection) => void;
  actionableZone?: Zone;
  selectingPassengers?: boolean;
}
