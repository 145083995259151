import { Ctx, MoveFn, PlayerID } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io/core";
import { ActionList, BusState, canEndTurn } from ".";
import { moveClock } from "./chooseClock";
import { ActionSpace } from "./placeAction";

interface CurrentAction {
  actionSpace: ActionSpace;
  position?: number;
  player: PlayerID | null;
}

export const getCurrentAction = (state: BusState): CurrentAction | null => {
  const lineAction = getActionFromList(state.placedActions.lineExpansion, true);
  if (lineAction) {
    return { ...lineAction, actionSpace: "lineExpansion" };
  }

  const busAction = state.placedActions.busses;
  if (busAction) {
    return { player: busAction, actionSpace: "busses" };
  }

  const passengerAction = getActionFromList(
    state.placedActions.passengers,
    false
  );
  if (passengerAction) {
    return { ...passengerAction, actionSpace: "passengers" };
  }

  const buildingAction = getActionFromList(state.placedActions.buildings, true);
  if (buildingAction) {
    return { ...buildingAction, actionSpace: "buildings" };
  }

  const clockAction = state.placedActions.clock;
  const resolvedClock = state.placedActions.resolvedClock;
  if (clockAction || !resolvedClock) {
    return { player: clockAction, actionSpace: "clock" };
  }

  const vroomAction = getActionFromList(state.placedActions.vroom, false);
  if (vroomAction) {
    return { ...vroomAction, actionSpace: "vroom" };
  }

  const startPlayerAction = state.placedActions.startingPlayer;
  const resolvedStartingPlayer = state.placedActions.resolvedStartingPlayer;
  if (startPlayerAction || !resolvedStartingPlayer) {
    return { player: startPlayerAction, actionSpace: "startingPlayer" };
  }

  return null;
};

const getActionFromList = (
  actionList: ActionList,
  reverse: boolean
): {
  position: number;
  player: PlayerID;
} | null => {
  if (reverse) {
    for (let i = actionList.length - 1; i >= 0; i--) {
      const player = actionList[i];
      if (player) return { player, position: i };
    }
  } else {
    for (let i = 0; i < actionList.length; i++) {
      const player = actionList[i];
      if (player) return { player, position: i };
    }
  }
  return null;
};

const resolveAction = (
  state: BusState,
  ctx: Ctx,
  action: CurrentAction
): boolean => {
  if (action.actionSpace === "vroom") {
    return false;
  }

  if (
    (action.actionSpace === "lineExpansion" ||
      action.actionSpace === "passengers" ||
      action.actionSpace === "buildings") &&
    action.position !== undefined
  ) {
    const numActions = getNumActionsForPosition(
      state,
      ctx,
      action.actionSpace,
      action.position
    );
    if (numActions === 0) {
      removeActionMarker(state, action);
      return true;
    }
    return false;
  }

  if (action.actionSpace === "busses" && action.player) {
    state.playerInfo[action.player]!.busses += 1;
    removeActionMarker(state, action);
    return true;
  }

  if (action.actionSpace === "clock") {
    if (action.player) {
      return false;
    } else {
      moveClock(state);
      return true;
    }
  }

  if (action.actionSpace === "startingPlayer") {
    if (action.player) {
      state.startPlayer = action.player;
      removeActionMarker(state, action);
    } else {
      const currentStartPos = ctx.playOrder.indexOf(state.startPlayer);
      const nextStartPos = (currentStartPos + 1) % ctx.numPlayers;
      state.startPlayer = ctx.playOrder[nextStartPos]!;
    }
    state.placedActions.resolvedStartingPlayer = true;
    return true;
  }

  return true;
};

export const resolveToNextPlayer = (state: BusState, ctx: Ctx) => {
  let needPlayerInput = false;
  let currentAction;
  do {
    currentAction = getCurrentAction(state);
    if (!currentAction) return null;
    needPlayerInput = !resolveAction(state, ctx, currentAction);
  } while (!needPlayerInput);
  return currentAction;
};

export const getNumActions = (
  state: BusState,
  ctx: Ctx,
  action: CurrentAction
) => {
  if (
    (action.actionSpace === "lineExpansion" ||
      action.actionSpace === "passengers" ||
      action.actionSpace === "buildings") &&
    action.position !== undefined
  ) {
    return getNumActionsForPosition(
      state,
      ctx,
      action.actionSpace,
      action.position
    );
  }

  if (action.actionSpace === "clock" && action.player) {
    return 1;
  }

  if (action.actionSpace === "vroom" && action.player) {
    return state.playerInfo[action.player]?.busses ?? 1;
  }

  return 0;
};

export const getNumActionsForPosition = (
  state: BusState,
  ctx: Ctx,
  actionSpace: "lineExpansion" | "passengers" | "buildings",
  position: number
) => {
  const maximumNumberOfBusses = getMaximumNumberOfBusses(state, ctx);
  const maxBusses =
    actionSpace === "passengers" || actionSpace === "buildings"
      ? maximumNumberOfBusses.future
      : maximumNumberOfBusses.current;

  let numActions = maxBusses - position;
  if (actionSpace === "lineExpansion" && ctx.numPlayers >= 5) {
    numActions += 1;
  }

  return Math.max(numActions, 0);
};

const getMaximumNumberOfBusses = (state: BusState, ctx: Ctx) => {
  const busCounts = ctx.playOrder.map((p) => state.playerInfo[p]!.busses);
  const current = Math.max(...busCounts);
  let future = current;

  if (
    state.placedActions.busses &&
    state.playerInfo[state.placedActions.busses]!.busses === current
  ) {
    future += 1;
  }

  return { current, future };
};

export const endResolveActionTurn: MoveFn<BusState> = ({
  G: state,
  ctx,
  events,
}) => {
  const canEnd = canEndTurn(state, ctx);
  if (!canEnd) return INVALID_MOVE;
  const action = getCurrentAction(state);
  if (action) {
    removeActionMarker(state, action);
    const nextAction = resolveToNextPlayer(state, ctx);
    if (nextAction && nextAction.player) {
      events.endTurn({ next: nextAction.player });
      return;
    }
  }

  events.endTurn();
};

const removeActionMarker = (state: BusState, action: CurrentAction) => {
  if (
    (action.actionSpace === "lineExpansion" ||
      action.actionSpace === "passengers" ||
      action.actionSpace === "buildings" ||
      action.actionSpace === "vroom") &&
    action.position !== undefined
  ) {
    state.placedActions[action.actionSpace][action.position] = null;
    return;
  }

  if (
    action.actionSpace === "busses" ||
    action.actionSpace === "clock" ||
    action.actionSpace === "startingPlayer"
  ) {
    state.placedActions[action.actionSpace] = null;
    return;
  }
};
