import { ActionSpace, getAvailableActions } from "../../game/placeAction";

interface Props {
  actionSpace: ActionSpace;
  placeAction: (actionSpace: ActionSpace) => void;
  availableActions: ReturnType<typeof getAvailableActions>;
  title: string;
  isActive: boolean;
  infoText?: string;
  children: React.ReactNode;
}

const ActionSpaceUI: React.FC<Props> = (props) => {
  const {
    actionSpace,
    placeAction,
    availableActions,
    title,
    isActive,
    children,
    infoText,
  } = props;

  const isAvailable = isActive && availableActions[actionSpace];

  return (
    <div
      onClick={isAvailable ? () => placeAction(actionSpace) : undefined}
      style={isAvailable ? { cursor: "pointer" } : {}}
    >
      <div style={{ display: "flex" }}>
        <p style={{ flexGrow: "1", marginTop: "8px" }}>{title}</p>
        {infoText && <p>{infoText}</p>}
      </div>
      {children}
    </div>
  );
};

export default ActionSpaceUI;
