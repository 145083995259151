import { Client, Lobby } from "boardgame.io/react";
import { useEffect, useState } from "react";
import BusBoard from "./BusBoard";
import Bus from "./game";
import getVersionInfo from "./getVersionInfo";

// const App = Client({
//   game: Bus,
//   board: BusBoard,
//   numPlayers: 3,
//   debug: {
//     collapseOnLoad: true,
//   },
// });

const App: React.FC = () => {
  const [rev, setRev] = useState<string>();
  useEffect(() => {
    getVersionInfo().then((versionInfo) => {
      if (versionInfo) {
        setRev(versionInfo);
      }
    });
  }, []);
  return (
    <>
      <Lobby
        gameServer={`https://${window.location.hostname}`}
        lobbyServer={`https://${window.location.hostname}`}
        gameComponents={[{ game: Bus, board: BusBoard }]}
      />
      {rev && `Version: ${rev}`}
    </>
  );
};

export default App;
