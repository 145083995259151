import { Ctx, Game, MoveFn, PlayerID } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io/core";
import { passClock, stopClock } from "./chooseClock";
import {
  deliverPassenger,
  getDeparturesAndDestinations,
} from "./deliverPassengers";
import { endGame, gameOverAtEndOfRound, gameOverAtEndOfTurn } from "./gameEnd";
import {
  getFirstAbleToPlaceAction,
  initializePlacedActions,
  pass,
  placeAction,
} from "./placeAction";
import { Building, getCurrentZone, placeBuilding } from "./placeBuildings";
import { getAvailableLines, placeLine } from "./placeLines";
import { getPassengersLeft, placePassenger } from "./placePassengers";
import {
  endResolveActionTurn,
  getCurrentAction,
  getNumActions,
  resolveToNextPlayer,
} from "./resolveActions";
import { Intersection } from "./staticBoard";

export const SETUP_BUILDINGS_PHASE = "setupBuildings";
export const SETUP_LINES_PHASE = "setupLines";
export const CHOOSE_ACTIONS_PHASE = "chooseActions";
export const RESOLVE_ACTIONS_PHASE = "resolveActions";

export type MoveKind =
  | "placeBuilding"
  | "placeLine"
  | "chooseAction"
  | "placePassenger"
  | "chooseClock"
  | "deliverPassenger"
  | null;

export const getMoveKind = (state: BusState, ctx: Ctx): MoveKind => {
  if (ctx.phase === SETUP_BUILDINGS_PHASE) return "placeBuilding";
  if (ctx.phase === SETUP_LINES_PHASE) return "placeLine";
  if (ctx.phase === CHOOSE_ACTIONS_PHASE) return "chooseAction";
  if (ctx.phase === RESOLVE_ACTIONS_PHASE) {
    const action = getCurrentAction(state);
    if (action) {
      if (action.actionSpace === "lineExpansion") return "placeLine";
      if (action.actionSpace === "passengers") return "placePassenger";
      if (action.actionSpace === "buildings") return "placeBuilding";
      if (action.actionSpace === "clock" && action.player) return "chooseClock";
      if (action.actionSpace === "vroom") return "deliverPassenger";
    }
  }
  return null;
};

export const canEndTurn = (state: BusState, ctx: Ctx) => {
  let noValidMoves = false;
  const moveKind = getMoveKind(state, ctx);
  if (moveKind === "placeBuilding") {
    const currentZone = getCurrentZone(state);
    noValidMoves = !currentZone;
  } else if (moveKind === "placeLine") {
    const availableLines = getAvailableLines(state, ctx);
    noValidMoves =
      availableLines !== "any" &&
      availableLines.availableFromStart.length === 0 &&
      availableLines.availableFromEnd.length === 0;
  } else if (moveKind === "chooseAction") {
  } else if (moveKind === "placePassenger") {
    const passengersLeft = getPassengersLeft(state);
    noValidMoves = passengersLeft <= 0;
  } else if (moveKind === "chooseClock") {
  } else if (moveKind === "deliverPassenger") {
    const { departures, destinations } = getDeparturesAndDestinations(
      state,
      ctx
    );
    noValidMoves = departures.length === 0 || destinations.length === 0;
  } else {
    return false;
  }

  return noValidMoves || state.actionsLeft <= 0;
};

const endTurn: MoveFn<BusState> = ({ G: state, ctx, events }) => {
  const canEnd = canEndTurn(state, ctx);
  if (!canEnd) return INVALID_MOVE;
  events.endTurn();
};

const Bus: Game<BusState> = {
  name: "Bus",
  minPlayers: 3,
  maxPlayers: 5,
  setup: ({ ctx, random }, setupData) => ({
    intersections: {
      13: initializeIntersection({ passengers: 1 }),
      14: initializeIntersection({ passengers: 1 }),
      18: initializeIntersection({ passengers: 1 }),
      19: initializeIntersection({ passengers: 1 }),
    },
    playerInfo: ctx.playOrder.reduce(
      (acc, playerId) => ({
        ...acc,
        [playerId]: {
          busses: 1,
          points: { value: 0, position: 0 },
          actionMarkers: 20,
          line: [],
          passed: false,
        },
      }),
      {} as PlayerInfo
    ),
    clock: {
      value: "house",
      timeStones: {
        max: ctx.numPlayers <= 3 ? 4 : 5,
        claimedBy: [],
      },
    },
    startPlayer: random.Shuffle(ctx.playOrder)[0] ?? "0",
    actions: 0,
    actionsLeft: 0,
    placedActions: initializePlacedActions(),
  }),
  events: {
    endPhase: false,
    endTurn: false,
    endGame: false,
    endStage: false,
  },
  phases: {
    [SETUP_BUILDINGS_PHASE]: {
      start: true,
      turn: {
        order: {
          first: ({ G: state, ctx }) =>
            ctx.playOrder.indexOf(state.startPlayer),
          next: ({ G: state, ctx }) => {
            const next = (ctx.playOrderPos + 1) % ctx.numPlayers;
            if (ctx.playOrder[next] === state.startPlayer) return undefined;
            return next;
          },
        },
        onBegin: ({ G: state }) => {
          state.actions = 2;
          state.actionsLeft = 2;
        },
      },
      moves: {
        placeBuilding,
        endTurn,
      },
      next: SETUP_LINES_PHASE,
    },
    [SETUP_LINES_PHASE]: {
      turn: {
        order: {
          first: ({ G: state, ctx }) =>
            ctx.playOrder.indexOf(state.startPlayer),
          next: ({ G: state, ctx }) => {
            const lineLength = currentPlayerInfo(state, ctx).line.length;
            const nextOffset = lineLength > 2 ? -1 : 1;
            const next = mod(ctx.playOrderPos + nextOffset, ctx.numPlayers);
            return next;
          },
        },
        onBegin: ({ G: state, ctx, events }) => {
          const lastPlayerPos = mod(
            ctx.playOrder.indexOf(state.startPlayer) - 1,
            ctx.numPlayers
          );
          const lastPlayer = ctx.playOrderPos === lastPlayerPos;
          if (lastPlayer && currentPlayerInfo(state, ctx).line.length >= 3) {
            return events.endPhase();
          }
          const actions = lastPlayer ? 2 : 1;
          state.actions = actions;
          state.actionsLeft = actions;
        },
      },
      moves: {
        placeLine,
        endTurn,
      },
      next: CHOOSE_ACTIONS_PHASE,
    },
    [CHOOSE_ACTIONS_PHASE]: {
      onBegin: ({ G: state, ctx }) => {
        for (const playerId of ctx.playOrder) {
          state.playerInfo[playerId]!.passed =
            state.playerInfo[playerId]!.actionMarkers <= 0;
        }

        state.placedActions = initializePlacedActions();
      },
      turn: {
        order: {
          first: ({ G: state, ctx }) =>
            getFirstAbleToPlaceAction(
              state,
              ctx,
              ctx.playOrder.indexOf(state.startPlayer)
            ) ?? 0,

          next: ({ G: state, ctx }) =>
            getFirstAbleToPlaceAction(state, ctx, ctx.playOrderPos + 1) ?? 0,
        },
        onBegin: ({ G: state }) => {
          state.actions = 1;
          state.actionsLeft = 1;
        },
        onEnd: ({ G: state, ctx }) => {
          const currentPlayerActions =
            state.playerInfo[ctx.currentPlayer]!.actionMarkers;
          if (currentPlayerActions <= 0) {
            state.playerInfo[ctx.currentPlayer]!.passed = true;
          }
        },
      },
      moves: {
        placeAction,
        endTurn,
        pass,
      },
      endIf: ({ G: state, ctx }) =>
        getFirstAbleToPlaceAction(state, ctx, 0) === null,
      next: RESOLVE_ACTIONS_PHASE,
    },
    [RESOLVE_ACTIONS_PHASE]: {
      onBegin: ({ G: state, ctx }) => {
        state.placedActions.resolvedClock = false;
        state.placedActions.resolvedStartingPlayer = false;

        for (const playerId of ctx.playOrder) {
          state.playerInfo[playerId]!.passed = false;
        }

        resolveToNextPlayer(state, ctx);
      },
      turn: {
        order: {
          first: ({ G: state, ctx }) => {
            const action = getCurrentAction(state);
            if (action && action.player) {
              return ctx.playOrder.indexOf(action.player);
            }
            return 0;
          },
          next: () => {
            return 0;
          },
        },
        onBegin: ({ G: state, ctx }) => {
          const action = getCurrentAction(state);
          let numActions = 0;
          if (action) {
            numActions = getNumActions(state, ctx, action);
          }
          state.actions = numActions;
          state.actionsLeft = numActions;
        },
        onEnd: (context) => {
          const { G: state, ctx } = context;
          if (gameOverAtEndOfTurn(state, ctx)) {
            endGame(context);
          }
        },
      },
      moves: {
        placeLine,
        placePassenger,
        placeBuilding,
        stopClock,
        passClock,
        deliverPassenger,
        endTurn: endResolveActionTurn,
      },
      endIf: ({ G: state }) => getCurrentAction(state) === null,
      next: CHOOSE_ACTIONS_PHASE,
      onEnd: (context) => {
        const { G: state, ctx } = context;
        if (gameOverAtEndOfRound(state, ctx)) {
          endGame(context);
        }
      },
    },
  },
};

interface PlayerInfo {
  [playerId: PlayerID]: {
    busses: number;
    points: { value: number; position: number };
    actionMarkers: number;
    line: Intersection[];
    passed: boolean;
  };
}

export interface IntersectionInfo {
  passengers: number;
  buildings: Building[];
}

export interface BusState {
  intersections: {
    [intersection: Intersection]: IntersectionInfo;
  };
  playerInfo: PlayerInfo;
  clock: {
    value: Building;
    timeStones: {
      max: number;
      claimedBy: PlayerID[];
    };
  };
  startPlayer: PlayerID;
  actionsLeft: number;
  actions: number;
  placedActions: {
    lineExpansion: ActionList;
    busses: PlayerID | null;
    passengers: ActionList;
    buildings: ActionList;
    clock: PlayerID | null;
    resolvedClock: boolean;
    vroom: ActionList;
    startingPlayer: PlayerID | null;
    resolvedStartingPlayer: boolean;
  };
}

export type ActionList = Array<PlayerID | null>;

export default Bus;

export const initializeIntersection = (input?: {
  passengers?: number;
  buildings?: Building[];
}): BusState["intersections"][0] => ({
  passengers: input?.passengers ?? 0,
  buildings: input?.buildings ?? [],
});

export const currentPlayerInfo = (state: BusState, ctx: Ctx) =>
  state.playerInfo[ctx.currentPlayer]!;

export const mod = (n: number, m: number) => ((n % m) + m) % m;

export const increaseScore = (state: BusState, ctx: Ctx) => {
  const playerInfo = currentPlayerInfo(state, ctx);
  const newScore = playerInfo.points.value + 1;

  const newPosition =
    ctx.playOrder
      .filter(
        (p) =>
          p !== ctx.currentPlayer &&
          state.playerInfo[p]!.points.value === newScore
      )
      .map((p) => state.playerInfo[p]!.points.position)
      .reduce((max, pos) => Math.max(max, pos), -1) + 1;

  playerInfo.points.value = newScore;
  playerInfo.points.position = newPosition;
};
