import React from "react";
import { IntersectionAction } from ".";
import { IntersectionInfo } from "../../game";
import {
  allZones,
  Intersection,
  Zone,
  ZoneCount,
} from "../../game/staticBoard";
import { intersectionPositions, zonePositions } from "./intersectionPositions";
import { lineWidth } from "./LineUI";
import BuildingIcon, { buildingColors } from "./BuildingIcon";
import { ReactComponent as Meeple } from "./icons/meeple.svg";
import Passengers from "./Passengers";
import { Building } from "../../game/placeBuildings";

interface Props {
  intersection: Intersection;
  zones: ZoneCount;
  intersectionInfo: IntersectionInfo;
  intersectionAction?: IntersectionAction;
  isActive: boolean;
  color: string;
  size: number;
  isTrainStation: boolean;
  currentClock: Building;
}

const IntersectionUI: React.FC<Props> = (props) => {
  const {
    intersection,
    zones,
    intersectionInfo,
    intersectionAction,
    isActive,
    color,
    size,
    isTrainStation,
    currentClock,
  } = props;

  const actionableIntersections =
    intersectionAction?.actionableIntersections ?? [];
  const onIntersectionAction = intersectionAction?.onIntersectionAction;
  const available = actionableIntersections.includes(intersection);
  const action =
    isActive && onIntersectionAction && available
      ? () => onIntersectionAction(intersection)
      : undefined;
  const actionableZone = intersectionAction?.actionableZone;

  const position = intersectionPositions[intersection];
  const x = position?.x ?? 0;
  const y = position?.y ?? 0;

  const intersectionZones = zoneList(zones);
  const zonePos = zonePositions[intersection];

  let fillColor = isTrainStation ? "#0A2D4A" : "white";
  if (
    !actionableZone &&
    available &&
    !intersectionAction?.selectingPassengers
  ) {
    fillColor = "red";
  }

  const finalSize = isTrainStation ? 5 : size;

  const radius = Math.max(minRadius, (lineWidth * finalSize) / 2);

  return (
    <g transform={`translate(${x},${y})`}>
      {intersectionZones.map((z, index) => {
        const pos = zonePos?.[index];
        if (!pos) return <React.Fragment key={index}></React.Fragment>;
        const currentBuilding = intersectionInfo.buildings[index];
        const currentActionableZone =
          available && z === actionableZone && !currentBuilding;
        const buildingColor =
          currentBuilding && buildingColors[currentBuilding];
        return (
          <React.Fragment key={index}>
            <line x2={pos.x} y2={pos.y} stroke={zoneColor} strokeWidth={3} />
            <g transform={`translate(${pos.x},${pos.y})`}>
              <circle
                r={intersectionRadius}
                fill={
                  currentActionableZone ? "red" : buildingColor ?? zoneColor
                }
              />
              {currentBuilding ? (
                <BuildingIcon building={currentBuilding} />
              ) : (
                <text
                  y={1}
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="white"
                  fontWeight="bold"
                >
                  {z}
                </text>
              )}
            </g>
          </React.Fragment>
        );
      })}
      <circle
        r={radius}
        fill={fillColor}
        stroke={color}
        strokeWidth={isTrainStation ? 0 : 3}
      />
      {isTrainStation && (
        <g transform="scale(0.025) translate(-450,-400)">
          <Meeple fill="white" />
        </g>
      )}
      <Passengers
        intersectionInfo={intersectionInfo}
        zonePos={zonePos}
        currentClock={currentClock}
        selectingPassengers={
          available && intersectionAction?.selectingPassengers
        }
      />
      {action && (
        <circle
          r="50"
          onClick={action}
          style={{ cursor: "pointer" }}
          opacity={0}
        />
      )}
    </g>
  );
};

export default IntersectionUI;

const zoneList = (zones: ZoneCount) => {
  const zoneList: Zone[] = [];
  for (const zone of allZones) {
    for (let i = 0; i < (zones[zone] ?? 0); i++) {
      zoneList.push(zone);
    }
  }
  return zoneList;
};

const zoneColor = "#426683";
const minRadius = 5;
export const intersectionRadius = 13;
