import { Ctx } from "boardgame.io";
import { playerColor } from "..";
import { getLineId, Line, LineId } from "../../game/placeLines";
import staticBoard, { zoneList } from "../../game/staticBoard";
import LineUI from "./LineUI";

interface Props {
  actionableLines: LineId[] | "any";
  ctx: Ctx;
  isActive: boolean;
  onLineAction?: (line: Line) => void;
  playerLines: LineId[][];
}

const Lines: React.FC<Props> = (props) => {
  const { actionableLines, ctx, isActive, onLineAction, playerLines } = props;

  return (
    <>
      {zoneList(staticBoard.zones).map(({ intersection }) => {
        const connectedIntersections =
          staticBoard.roadGraph.adjacencyLists[intersection] ?? [];
        return connectedIntersections
          .filter((i) => i > intersection)
          .map((i) => {
            const line = { small: intersection, big: i };
            const lineId = getLineId(line);
            let actionColor = undefined;
            let action = undefined;
            if (actionableLines === "any" || actionableLines.includes(lineId)) {
              actionColor = playerColor(ctx.playOrderPos);
              if (isActive) {
                action = () => onLineAction?.(line);
              }
            }

            const placedLinesPlayerPositions = [...ctx.playOrder.keys()].filter(
              (i) => playerLines[i]?.includes(lineId)
            );

            return (
              <LineUI
                line={line}
                key={lineId}
                actionColor={actionColor}
                onLineAction={action}
                placedLinesPlayerPositions={placedLinesPlayerPositions}
              />
            );
          });
      })}
    </>
  );
};

export default Lines;
