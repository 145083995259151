import { Ctx, MoveFn } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io/core";
import { BusState, currentPlayerInfo, getMoveKind } from ".";

export type ActionSpace =
  | "lineExpansion"
  | "busses"
  | "passengers"
  | "buildings"
  | "clock"
  | "vroom"
  | "startingPlayer";

export const initializePlacedActions = (): BusState["placedActions"] => ({
  lineExpansion: [],
  busses: null,
  passengers: [],
  buildings: [],
  clock: null,
  resolvedClock: false,
  vroom: [],
  startingPlayer: null,
  resolvedStartingPlayer: false,
});

export const getAvailableActions = (
  state: BusState,
  ctx: Ctx
): { [key in ActionSpace]: boolean } => {
  const playerInfo = currentPlayerInfo(state, ctx);
  if (
    state.actionsLeft <= 0 ||
    playerInfo.passed ||
    playerInfo.actionMarkers <= 0
  ) {
    return {
      lineExpansion: false,
      busses: false,
      passengers: false,
      buildings: false,
      clock: false,
      vroom: false,
      startingPlayer: false,
    };
  }
  const {
    lineExpansion,
    busses,
    passengers,
    buildings,
    clock,
    vroom,
    startingPlayer,
  } = state.placedActions;
  return {
    lineExpansion: lineExpansion.length < 6,
    busses: !busses,
    passengers: passengers.length < 6,
    buildings: buildings.length < 6,
    clock: !clock,
    vroom: vroom.length < 6,
    startingPlayer: !startingPlayer,
  };
};

export const placeAction: MoveFn<BusState> = (
  { G: state, ctx },
  actionSpace: ActionSpace
) => {
  const availableActions = getAvailableActions(state, ctx);
  if (!availableActions[actionSpace]) return INVALID_MOVE;
  if (getMoveKind(state, ctx) !== "chooseAction") return INVALID_MOVE;

  if (actionSpace === "lineExpansion") {
    state.placedActions.lineExpansion.push(ctx.currentPlayer);
  } else if (actionSpace === "busses") {
    state.placedActions.busses = ctx.currentPlayer;
  } else if (actionSpace === "passengers") {
    state.placedActions.passengers.push(ctx.currentPlayer);
  } else if (actionSpace === "buildings") {
    state.placedActions.buildings.push(ctx.currentPlayer);
  } else if (actionSpace === "clock") {
    state.placedActions.clock = ctx.currentPlayer;
  } else if (actionSpace === "vroom") {
    state.placedActions.vroom.push(ctx.currentPlayer);
  } else if (actionSpace === "startingPlayer") {
    state.placedActions.startingPlayer = ctx.currentPlayer;
  }

  state.actionsLeft -= 1;
  currentPlayerInfo(state, ctx).actionMarkers -= 1;
};

export const pass: MoveFn<BusState> = ({ G: state, ctx, events }) => {
  if (!canPass(state, ctx)) return INVALID_MOVE;
  currentPlayerInfo(state, ctx).passed = true;
  events.endTurn();
};

export const canPass = (state: BusState, ctx: Ctx) => {
  const placedActions = getPlacedActionCount(state, ctx);
  return placedActions >= 2 && state.actionsLeft === state.actions;
};

const getPlacedActionCount = (state: BusState, ctx: Ctx) => {
  let placedActions = 0;
  placedActions += state.placedActions.lineExpansion.filter(
    (p) => p === ctx.currentPlayer
  ).length;
  placedActions += state.placedActions.busses === ctx.currentPlayer ? 1 : 0;
  placedActions += state.placedActions.passengers.filter(
    (p) => p === ctx.currentPlayer
  ).length;
  placedActions += state.placedActions.buildings.filter(
    (p) => p === ctx.currentPlayer
  ).length;
  placedActions += state.placedActions.clock === ctx.currentPlayer ? 1 : 0;
  placedActions += state.placedActions.vroom.filter(
    (p) => p === ctx.currentPlayer
  ).length;
  placedActions +=
    state.placedActions.startingPlayer === ctx.currentPlayer ? 1 : 0;
  return placedActions;
};

export const getFirstAbleToPlaceAction = (
  state: BusState,
  ctx: Ctx,
  startPos: number
) => {
  let initial = NaN;
  let next = startPos - 1;
  let canPlay = true;
  do {
    next = (next + 1) % ctx.numPlayers;
    if (next === initial) return null;
    initial = startPos;
    let nextPlayerInfo = state.playerInfo[ctx.playOrder[next]!]!;
    canPlay = !nextPlayerInfo.passed;
  } while (!canPlay);
  return next;
};
