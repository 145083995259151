import { Ctx, MoveFn } from "boardgame.io";
import { BusState, currentPlayerInfo, getMoveKind, increaseScore } from ".";
import { Intersection } from "./staticBoard";
import { INVALID_MOVE } from "boardgame.io/core";
import { isValidIntersection } from "./placeBuildings";

const getExtraPassengers = (state: BusState, intersection: Intersection) => {
  const intersectionValue = state.intersections[intersection];
  const passengers = intersectionValue?.passengers ?? 0;
  const buildings =
    intersectionValue?.buildings.filter((b) => b === state.clock.value)
      .length ?? 0;
  return passengers - buildings;
};

export const getDeparturesAndDestinations = (state: BusState, ctx: Ctx) => {
  const playerInfo = currentPlayerInfo(state, ctx);
  const departures = [];
  const destinations = [];

  for (const intersection of playerInfo.line) {
    const extraPassengers = getExtraPassengers(state, intersection);
    if (extraPassengers > 0) {
      departures.push(intersection);
    } else if (extraPassengers < 0) {
      destinations.push(intersection);
    }
  }

  return { departures, destinations };
};

export const deliverPassenger: MoveFn<BusState> = (
  { G: state, ctx },
  departingFrom: Intersection,
  goingTo: Intersection
) => {
  if (state.actionsLeft <= 0) return INVALID_MOVE;
  if (!isValidIntersection(departingFrom) || !isValidIntersection(goingTo))
    return INVALID_MOVE;
  if (getMoveKind(state, ctx) !== "deliverPassenger") return INVALID_MOVE;

  const { departures, destinations } = getDeparturesAndDestinations(state, ctx);

  if (!departures.includes(departingFrom) || !destinations.includes(goingTo)) {
    return INVALID_MOVE;
  }

  state.intersections[departingFrom]!.passengers -= 1;
  state.intersections[goingTo]!.passengers += 1;
  increaseScore(state, ctx);

  state.actionsLeft -= 1;
};
