import { Ctx } from "boardgame.io";
import { ActionList, BusState } from "../../game";
import { getNumActionsForPosition } from "../../game/resolveActions";
import { circleRadius, colorFromPlayerId } from "./spaces";

interface Props {
  state: BusState;
  actionSpace: KeysMatching<BusState["placedActions"], ActionList>;
  reverse?: boolean;
  ctx: Ctx;
}

const LetterSpaces: React.FC<Props> = (props) => {
  const { state, reverse, actionSpace, ctx } = props;
  const placedActions = state.placedActions[actionSpace];
  const isVroom = actionSpace === "vroom";

  return (
    <svg
      height={2 * circleRadius + (isVroom ? 0 : 20)}
      width={12 * circleRadius + 5 * 8}
    >
      {letters.map((l, i) => {
        const transform = reverse
          ? `translate(${205 - (2 * circleRadius + 8) * i},${circleRadius})`
          : `translate(${
              circleRadius + (2 * circleRadius + 8) * i
            },${circleRadius})`;

        const playerPiece = placedActions.map(
          (p) => p && colorFromPlayerId(ctx, p)
        )[i];

        return (
          <g transform={transform} key={l}>
            <circle r={circleRadius} fill={playerPiece ?? "gray"} />
            {!playerPiece && (
              <text fill="white" dominantBaseline="central" textAnchor="middle">
                {l}
              </text>
            )}
            {!isVroom && (
              <text y={32} textAnchor="middle">
                {getNumActionsForPosition(state, ctx, actionSpace, i)}
              </text>
            )}
          </g>
        );
      })}
    </svg>
  );
};

export default LetterSpaces;

const letters = ["A", "B", "C", "D", "E", "F"];

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
