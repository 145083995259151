import { playerColor } from "..";
import { Line } from "../../game/placeLines";
import { intersectionPositions } from "./intersectionPositions";

interface Props {
  line: Line;
  actionColor?: string;
  onLineAction?: () => void;
  placedLinesPlayerPositions: number[];
}

const LineUI: React.FC<Props> = (props) => {
  const { line, actionColor, onLineAction, placedLinesPlayerPositions } = props;
  const { small, big } = line;

  const pos1 = intersectionPositions[small];
  const x1 = pos1?.x ?? 0;
  const y1 = pos1?.y ?? 0;
  const pos2 = intersectionPositions[big];
  const x2 = pos2?.x ?? 0;
  const y2 = pos2?.y ?? 0;
  let angle = Math.atan2(y2 - y1, x2 - x1) + Math.PI / 2;
  const cos = Math.cos(angle);
  const sin = Math.sin(angle);

  const linesToRender: LineColor[] = placedLinesPlayerPositions.map(
    (playerpos) => ({
      color: playerColor(playerpos),
    })
  );
  if (actionColor) {
    linesToRender.push({ color: actionColor, action: true });
  }
  if (linesToRender.length === 0) {
    linesToRender.push({ color: "black", action: true });
  }

  const offset = (lineWidth / 2) * (linesToRender.length - 1);

  return (
    <>
      {linesToRender.map((line, i) => {
        const iOffset = offset - lineWidth * i;
        const xOffset = iOffset * cos;
        const yOffset = iOffset * sin;
        return (
          <line
            key={line.color + (line.action ? "-a" : "")}
            x1={x1 + xOffset}
            y1={y1 + yOffset}
            x2={x2 + xOffset}
            y2={y2 + yOffset}
            stroke={line.color}
            strokeWidth={lineWidth}
            opacity={line.action ? 0.2 : 1}
            onClick={onLineAction}
            style={onLineAction ? { cursor: "pointer" } : {}}
          />
        );
      })}
    </>
  );
};

export default LineUI;

interface LineColor {
  color: string;
  action?: boolean;
}

export const lineWidth = 7;
