import { Building, buildings } from "../../game/placeBuildings";
import { buildingColors } from "../BusMap/BuildingIcon";
import { ReactComponent as House } from "../BusMap/icons/house.svg";
import { ReactComponent as Office } from "../BusMap/icons/office.svg";
import { ReactComponent as Pub } from "../BusMap/icons/pub.svg";

interface Props {
  currentTime: Building;
}

const width = 90;
const radius = width / 2;

const center = [radius, radius].join(",");

const point = (n: number) => {
  const angle = (Math.PI / 6) * (4 * n - 3);
  return [radius + radius * Math.cos(angle), radius + radius * Math.sin(angle)];
};

const point0 = point(0);
const point1 = point(1);
const point2 = point(2);

const Clock: React.FC<Props> = (props) => {
  const { currentTime } = props;

  const index = buildings.indexOf(currentTime);
  const currentAngle = 60 + index * 120;

  return (
    <svg width={width} height={width}>
      <path
        fill={buildingColors.house}
        d={`M${center} L${point0.join(
          ","
        )} A${radius},${radius},0,0,1,${point1.join(",")} Z`}
      />
      <path
        fill={buildingColors.office}
        d={`M${center} L${point1.join(
          ","
        )} A${radius},${radius},0,0,1,${point2.join(",")} Z`}
      />
      <path
        fill={buildingColors.pub}
        d={`M${center} L${point2.join(
          ","
        )} A${radius},${radius},0,0,1,${point0.join(",")} Z`}
      />
      <circle
        cx={radius}
        cy={radius}
        r={radius - 2}
        fill="none"
        stroke="black"
        strokeWidth={4}
      />
      <line
        x1={radius}
        y1={radius}
        x2={point0[0]}
        y2={point0[1]}
        stroke="black"
        strokeWidth={4}
      />
      <line
        x1={radius}
        y1={radius}
        x2={point1[0]}
        y2={point1[1]}
        stroke="black"
        strokeWidth={4}
      />
      <line
        x1={radius}
        y1={radius}
        x2={point2[0]}
        y2={point2[1]}
        stroke="black"
        strokeWidth={4}
      />
      <g transform="scale(1.2) translate(44, 15)">
        <House fill="white" />
      </g>
      <g transform="scale(1.2) translate(25.5, 46)">
        <Office fill="white" />
      </g>
      <g transform="scale(1.2) translate(8, 15)">
        <Pub fill="white" />
      </g>
      <path
        transform={`translate(${center}) rotate(${currentAngle})`}
        d="M-3,0 L-3,-20 L0,-25 L3,-20 L3,0 Z"
        fill="black"
      />
      <circle cx={radius} cy={radius} r={7} fill="black" />
    </svg>
  );
};

export default Clock;
