import { useEffect, useRef } from "react";
import { Building, buildings } from "../../game/placeBuildings";
import { Intersection } from "../../game/staticBoard";
import BuildingIcon, { buildingColors } from "./BuildingIcon";
import { intersectionRadius } from "./IntersectionUI";
import { intersectionPositions } from "./intersectionPositions";

interface Props {
  buildingAt: Intersection;
  chooseBuilding: (building?: Building) => void;
}

const BuildingPopup: React.FC<Props> = (props) => {
  const { buildingAt, chooseBuilding } = props;
  const wrapperRef = useRef<SVGForeignObjectElement>(null);
  useOutsideAlerter(wrapperRef, chooseBuilding);

  const pos = intersectionPositions[buildingAt]!;

  return (
    <foreignObject
      x={pos.x - popupWidth / 2}
      y={pos.y - 100}
      width={popupWidth}
      height={52}
      ref={wrapperRef}
    >
      <div
        style={{
          background: "white",
          borderStyle: "solid",
          borderRadius: "100px",
          padding: "8px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {buildings.map((building) => {
          return (
            <svg
              width={2 * intersectionRadius}
              height={2 * intersectionRadius}
              key={building}
              onClick={() => chooseBuilding(building)}
              style={{ cursor: "pointer" }}
            >
              <g
                transform={`translate(${intersectionRadius},${intersectionRadius})`}
              >
                <circle
                  r={intersectionRadius}
                  fill={buildingColors[building]}
                />
                <BuildingIcon building={building} />
              </g>
            </svg>
          );
        })}
      </div>
    </foreignObject>
  );
};

export default BuildingPopup;

const useOutsideAlerter = (
  ref: React.RefObject<SVGForeignObjectElement>,
  chooseBuilding: (building?: Building) => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        event.target instanceof Node &&
        !ref.current.contains(event.target)
      ) {
        chooseBuilding?.(undefined);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, chooseBuilding]);
};

const popupWidth = 136;
