import { MoveFn } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io/core";
import { BusState, getMoveKind } from ".";

export const moveClock = (state: BusState) => {
  const currentClock = state.clock.value;
  if (currentClock === "house") {
    state.clock.value = "office";
  } else if (currentClock === "office") {
    state.clock.value = "pub";
  } else if (currentClock === "pub") {
    state.clock.value = "house";
  }
  state.placedActions.resolvedClock = true;
};

export const passClock: MoveFn<BusState> = ({ G: state, ctx }) => {
  if (state.actionsLeft <= 0) return INVALID_MOVE;
  if (getMoveKind(state, ctx) !== "chooseClock") return INVALID_MOVE;

  moveClock(state);

  state.actionsLeft -= 1;
};

export const stopClock: MoveFn<BusState> = ({ G: state, ctx }) => {
  if (state.actionsLeft <= 0) return INVALID_MOVE;
  if (getMoveKind(state, ctx) !== "chooseClock") return INVALID_MOVE;

  state.clock.timeStones.claimedBy.push(ctx.currentPlayer);
  state.placedActions.resolvedClock = true;

  state.actionsLeft -= 1;
};
