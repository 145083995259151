import { MoveFn } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io/core";
import { BusState, getMoveKind, initializeIntersection } from ".";
import { isValidIntersection } from "./placeBuildings";
import { Intersection } from "./staticBoard";

export const trainStations: Intersection[] = [9, 25];

export const placePassenger: MoveFn<BusState> = (
  { G: state, ctx },
  intersection: unknown
) => {
  if (getMoveKind(state, ctx) !== "placePassenger") return INVALID_MOVE;
  if (state.actionsLeft <= 0) return INVALID_MOVE;
  if (!isValidIntersection(intersection)) return INVALID_MOVE;
  if (!trainStations.includes(intersection)) return INVALID_MOVE;
  if (getPassengersLeft(state) <= 0) return INVALID_MOVE;

  const { intersections } = state;
  if (!intersections[intersection]) {
    intersections[intersection] = initializeIntersection();
  }
  intersections[intersection]!.passengers += 1;

  state.actionsLeft -= 1;
};

export const getPassengersLeft = (state: BusState) => {
  const currentPassengers = Object.values(state.intersections)
    .map((i) => i.passengers)
    .reduce((a, b) => a + b, 0);
  return MAX_PASSENGERS - currentPassengers;
};

const MAX_PASSENGERS = 15;
