import { Ctx } from "boardgame.io";
import { BoardProps } from "boardgame.io/react";
import { useMemo, useState } from "react";
import { BusState, canEndTurn, currentPlayerInfo, getMoveKind } from "../game";
import { getDeparturesAndDestinations } from "../game/deliverPassengers";
import { gameOverAtEndOfTurn } from "../game/gameEnd";
import { canPass } from "../game/placeAction";
import { Building, getOpenBuildingIntersections } from "../game/placeBuildings";
import {
  getAvailableLines,
  getLineId,
  getLineSegments,
  Line,
  LineId,
} from "../game/placeLines";
import { trainStations } from "../game/placePassengers";
import { Intersection } from "../game/staticBoard";
import ActionsUI from "./actions/ActionsUI";
import BusMap, { IntersectionAction } from "./BusMap";
import PlayerInfo from "./PlayerInfo";

const BusBoard: React.FC<BoardProps<BusState>> = (props) => {
  const { G: state, ctx, moves, undo, isActive } = props;

  const moveKind = getMoveKind(state, ctx);

  let doneMoves = canEndTurn(state, ctx);
  const canUndo = isActive && moveKind && state.actionsLeft < state.actions;

  let bannerContent = (
    <>
      <span style={{ color: "white", alignSelf: "center" }}>
        {moveText(state, ctx)}
      </span>
      {isActive && doneMoves && (
        <button onClick={() => moves.endTurn?.()}>End Turn</button>
      )}
      {canUndo && <button onClick={undo}>Undo</button>}
      {gameOverAtEndOfTurn(state, ctx) && !ctx.gameover && (
        <span style={{ color: "white", alignSelf: "center" }}>
          Game Will End
        </span>
      )}
    </>
  );

  const [buildingAt, setBuildingAt] = useState<Intersection>();
  const [deliveringFrom, setDeliveringFrom] = useState<Intersection>();
  const [choosingLineEnd, setChoosingLineEnd] = useState<Line>();

  let intersectionAction: IntersectionAction | undefined = undefined;

  let actionableLines: LineId[] | "any" = [];
  let onLineAction: ((line: Line) => void) | undefined = undefined;

  const chooseBuilding = useMemo(
    () =>
      buildingAt !== undefined
        ? (building?: Building) => {
            if (building) {
              moves.placeBuilding?.(buildingAt, building);
            }
            setBuildingAt(undefined);
          }
        : undefined,
    [buildingAt, moves]
  );

  if (!doneMoves) {
    if (moveKind === "placeBuilding") {
      const openIntersections = getOpenBuildingIntersections(state);
      if (openIntersections) {
        intersectionAction = {
          actionableIntersections: openIntersections.openBuildingIntersections,
          onIntersectionAction: (intersection) => {
            setBuildingAt(intersection);
          },
          actionableZone: openIntersections.currentZone,
        };
      }
    } else if (moveKind === "placeLine") {
      if (choosingLineEnd) {
        intersectionAction = {
          actionableIntersections: [choosingLineEnd.small, choosingLineEnd.big],
          onIntersectionAction: (intersection) => {
            const currentPlayerLine = currentPlayerInfo(state, ctx).line;
            let fromWhichEnd: "start" | "end" = "start";
            if (intersection === currentPlayerLine[0]) {
              fromWhichEnd = "end";
            }

            moves.placeLine?.(getLineId(choosingLineEnd), fromWhichEnd);
            setChoosingLineEnd(undefined);
          },
        };
        bannerContent = (
          <span style={{ color: "white", alignSelf: "center" }}>
            Select the end of your line
          </span>
        );
      } else {
        const availableLines = getAvailableLines(state, ctx);
        if (availableLines === "any") {
          actionableLines = availableLines;
          onLineAction = (line) => moves.placeLine?.(getLineId(line));
        } else {
          actionableLines = availableLines.availableFromStart.concat(
            availableLines.availableFromEnd
          );
          onLineAction = (line) => {
            const lineId = getLineId(line);
            const fromStart =
              availableLines.availableFromStart.includes(lineId);
            const fromEnd = availableLines.availableFromEnd.includes(lineId);
            if (fromStart && fromEnd) {
              const playerLine = currentPlayerInfo(state, ctx).line;
              if (playerLine[0] === playerLine[playerLine.length - 1]) {
                moves.placeLine?.(lineId, "end");
              } else {
                setChoosingLineEnd(line);
              }
            } else {
              moves.placeLine?.(lineId);
            }
          };
        }
      }
    } else if (moveKind === "chooseAction") {
      if (isActive && canPass(state, ctx)) {
        bannerContent = (
          <>
            <span style={{ color: "white", alignSelf: "center" }}>
              {moveText(state, ctx)}
            </span>
            <button onClick={() => moves.pass?.()}>Pass</button>
          </>
        );
      }
    } else if (moveKind === "placePassenger") {
      intersectionAction = {
        actionableIntersections: trainStations,
        onIntersectionAction: (intersection) => {
          moves.placePassenger?.(intersection);
        },
      };
    } else if (moveKind === "chooseClock") {
      bannerContent = (
        <>
          <button onClick={() => moves.stopClock?.()}>Stop Clock</button>
          <button onClick={() => moves.passClock?.()}>Pass</button>
        </>
      );
    } else if (moveKind === "deliverPassenger") {
      const { departures, destinations } = getDeparturesAndDestinations(
        state,
        ctx
      );
      if (!doneMoves) {
        if (!deliveringFrom) {
          intersectionAction = {
            actionableIntersections: departures,
            onIntersectionAction: (intersection) => {
              setDeliveringFrom(intersection);
            },
            selectingPassengers: true,
          };
        } else {
          intersectionAction = {
            actionableIntersections: destinations,
            onIntersectionAction: (intersection) => {
              moves.deliverPassenger?.(deliveringFrom, intersection);
              setDeliveringFrom(undefined);
            },
          };
        }
      }
    }
  }

  const playerLines = ctx.playOrder.map((playerId) => {
    const segments = getLineSegments(state.playerInfo[playerId]?.line ?? []);
    if (choosingLineEnd && playerId === ctx.currentPlayer) {
      segments.push(getLineId(choosingLineEnd));
    }
    return segments;
  });

  return (
    <>
      <div
        style={{
          background: playerColor(ctx.playOrderPos),
          minHeight: "40px",
          padding: "8px",
          display: "flex",
          justifyContent: "center",
          gap: "8px",
        }}
        className="no-select"
      >
        {bannerContent}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <BusMap
            state={state}
            ctx={ctx}
            actionableLines={actionableLines}
            isActive={isActive}
            onLineAction={onLineAction}
            playerLines={playerLines}
            intersectionAction={intersectionAction}
            buildingAt={buildingAt}
            chooseBuilding={chooseBuilding}
          />
          <PlayerInfo state={state} ctx={ctx} />
        </div>
        <ActionsUI
          state={state}
          ctx={ctx}
          placeAction={moves.placeAction as any}
          isActive={isActive}
        />
      </div>
    </>
  );
};

export default BusBoard;

const moveText = (state: BusState, ctx: Ctx) => {
  const moveKind = getMoveKind(state, ctx);
  if (moveKind === "placeBuilding") {
    return `Place ${state.actionsLeft} buildings`;
  } else if (moveKind === "placeLine") {
    return `Place ${state.actionsLeft} lines`;
  } else if (moveKind === "chooseAction") {
    return "Place an action marker";
  } else if (moveKind === "placePassenger") {
    return `Add ${state.actionsLeft} passengers`;
  } else if (moveKind === "deliverPassenger") {
    return `Deliver ${state.actionsLeft} passengers`;
  }
};

export const playerColor = (playerPos: number) => {
  if (playerPos === 0) return "#1B5495";
  if (playerPos === 1) return "#FACD22";
  if (playerPos === 2) return "#E5702A";
  if (playerPos === 3) return "#2B9546";
  if (playerPos === 4) return "#2F91B5";
  return undefined!;
};
