import { Ctx } from "boardgame.io";
import { BusState } from "../../game";
import { circleRadius, colorFromPlayerId } from "./spaces";

interface Props {
  state: BusState;
  ctx: Ctx;
}

const TimeStones: React.FC<Props> = (props) => {
  const { state, ctx } = props;

  return (
    <svg height={2 * circleRadius} width={182} style={{ marginTop: "8px" }}>
      {[...Array(state.clock.timeStones.max).keys()].map((n) => {
        const claimedBy = state.clock.timeStones.claimedBy[n];
        const fill = claimedBy ? colorFromPlayerId(ctx, claimedBy) : "grey";
        return (
          <rect
            key={n}
            width={2 * circleRadius}
            height={2 * circleRadius}
            transform={`translate(${n * (2 * circleRadius + 8)},0)`}
            fill={fill}
          />
        );
      })}
    </svg>
  );
};

export default TimeStones;
