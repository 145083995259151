import { Ctx } from "boardgame.io";
import { playerColor } from "..";
import { BusState, CHOOSE_ACTIONS_PHASE } from "../../game";
import { ActionSpace, getAvailableActions } from "../../game/placeAction";
import ActionSpaceUI from "./ActionSpaceUI";
import LetterSpaces from "./LetterSpaces";
import SingleSpace from "./SingleSpace";
import { circleRadius } from "./spaces";
import TimeStones from "./TimeStones";
import { getPassengersLeft } from "../../game/placePassengers";
import { gameOverAtEndOfRound } from "../../game/gameEnd";
import Clock from "./Clock";

interface Props {
  state: BusState;
  ctx: Ctx;
  placeAction: (actionSpace: ActionSpace) => void;
  isActive: boolean;
}

const ActionsUI: React.FC<Props> = (props) => {
  let { state, ctx, placeAction, isActive } = props;

  const availableActions = getAvailableActions(state, ctx);

  isActive &&= ctx.phase === CHOOSE_ACTIONS_PHASE;

  const actionSpaceProps = {
    availableActions,
    placeAction,
    isActive,
  };

  return (
    <div
      style={{ minWidth: "240px", padding: "8px", borderLeft: "2px solid" }}
      className="no-select"
    >
      Actions
      <hr />
      <ActionSpaceUI
        title="Line Expansion"
        actionSpace="lineExpansion"
        {...actionSpaceProps}
      >
        <LetterSpaces
          actionSpace="lineExpansion"
          state={state}
          reverse
          ctx={ctx}
        />
      </ActionSpaceUI>
      <hr />
      <ActionSpaceUI title="Busses" actionSpace="busses" {...actionSpaceProps}>
        <SingleSpace placedAction={state.placedActions.busses} ctx={ctx} />
        <svg
          height={2 * circleRadius}
          width={2 * circleRadius * 5 + 4 * 8}
          style={{ marginLeft: "16px" }}
        >
          {ctx.playOrder.map((p, i) => (
            <g
              key={p}
              transform={`translate(${i * (2 * circleRadius + 8)},${0})`}
            >
              <rect
                width={2 * circleRadius}
                height={2 * circleRadius}
                fill={playerColor(i)}
              />
              <text
                x={circleRadius}
                y={circleRadius}
                fill="white"
                dominantBaseline="central"
                textAnchor="middle"
              >
                {state.playerInfo[p]!.busses}
              </text>
            </g>
          ))}
        </svg>
      </ActionSpaceUI>
      <hr />
      <ActionSpaceUI
        title="Passengers"
        actionSpace="passengers"
        infoText={getPassengersLeft(state) + " left"}
        {...actionSpaceProps}
      >
        <LetterSpaces actionSpace="passengers" state={state} ctx={ctx} />
      </ActionSpaceUI>
      <hr />
      <ActionSpaceUI
        title="Buildings"
        actionSpace="buildings"
        {...actionSpaceProps}
      >
        <LetterSpaces actionSpace="buildings" state={state} reverse ctx={ctx} />
      </ActionSpaceUI>
      <hr />
      <ActionSpaceUI title="Clock" actionSpace="clock" {...actionSpaceProps}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <SingleSpace placedAction={state.placedActions.clock} ctx={ctx} />
          <Clock currentTime={state.clock.value} />
        </div>
        <TimeStones state={state} ctx={ctx} />
      </ActionSpaceUI>
      <hr />
      <ActionSpaceUI
        title="Vrrooomm!"
        actionSpace="vroom"
        {...actionSpaceProps}
      >
        <LetterSpaces actionSpace="vroom" state={state} ctx={ctx} />
      </ActionSpaceUI>
      <hr />
      <ActionSpaceUI
        title="Starting Player"
        actionSpace="startingPlayer"
        {...actionSpaceProps}
      >
        <SingleSpace
          placedAction={state.placedActions.startingPlayer}
          ctx={ctx}
        />
      </ActionSpaceUI>
      {gameOverAtEndOfRound(state, ctx) && <p>Game Will End</p>}
    </div>
  );
};

export default ActionsUI;
