import { Ctx, FnContext, PlayerID } from "boardgame.io";
import { BusState } from ".";
import { getCurrentZone } from "./placeBuildings";

export const gameOverAtEndOfRound = (state: BusState, ctx: Ctx) => {
  const numPlayersWithActionsLeft = ctx.playOrder.filter(
    (p) => state.playerInfo[p]!.actionMarkers > 0
  ).length;
  if (numPlayersWithActionsLeft <= 1) return true;

  const noMoreBuildingSpaces = getCurrentZone(state) === null;
  return noMoreBuildingSpaces;
};

export const getPlayerRankings = (state: BusState, ctx: Ctx) => {
  const rankings: { [rank: number]: PlayerID[] } = {};

  for (const player of ctx.playOrder) {
    const ranking = getPlayerRank(state, ctx, player);
    if (!rankings[ranking]) {
      rankings[ranking] = [];
    }
    rankings[ranking]!.push(player);
  }

  return rankings;
};

const getPlayerTimeStones = (state: BusState, player: PlayerID) => {
  const numTimeStones = state.clock.timeStones.claimedBy.filter(
    (p) => p === player
  ).length;
  return numTimeStones;
};

export const getPlayerTotalScore = (state: BusState, player: PlayerID) => {
  const baseScore = state.playerInfo[player]?.points.value ?? 0;
  const numTimeStones = getPlayerTimeStones(state, player);
  return baseScore - numTimeStones;
};

const comparePlayerScores = (state: BusState, a: PlayerID, b: PlayerID) => {
  const aScore = getPlayerTotalScore(state, a);
  const bScore = getPlayerTotalScore(state, b);
  if (aScore !== bScore) return bScore - aScore;

  const aTimeStones = getPlayerTimeStones(state, a);
  const bTimeStones = getPlayerTimeStones(state, b);
  if (aTimeStones !== bTimeStones) return bTimeStones - aTimeStones;

  const aPosition = state.playerInfo[a]?.points.position ?? 0;
  const bPosition = state.playerInfo[b]?.points.position ?? 0;
  return aPosition - bPosition;
};

const getPlayerRank = (state: BusState, ctx: Ctx, player: PlayerID) => {
  let numBetter = 0;

  for (const other of ctx.playOrder) {
    if (other === player) continue;
    if (comparePlayerScores(state, other, player) < 0) {
      numBetter += 1;
    }
  }

  return numBetter + 1;
};

export const gameOverAtEndOfTurn = (state: BusState, ctx: Ctx) => {
  const { max, claimedBy } = state.clock.timeStones;
  const stonesLeft = max - claimedBy.length;
  return stonesLeft <= 0;
};

export const endGame = ({ G: state, ctx, events }: FnContext<BusState>) => {
  events.endGame(getPlayerRankings(state, ctx));
};
