export type Intersection = number;

class Graph {
  adjacencyLists: Intersection[][];

  constructor(vertexNumber: number) {
    this.adjacencyLists = Array.from({ length: vertexNumber }, () => []);
  }

  addEdge(a: Intersection, b: Intersection) {
    this.adjacencyLists[a]?.push(b);
    this.adjacencyLists[b]?.push(a);
  }
}

const roadGraph = new Graph(36);
roadGraph.addEdge(0, 1);
roadGraph.addEdge(0, 5);
roadGraph.addEdge(0, 11);

roadGraph.addEdge(1, 2);
roadGraph.addEdge(1, 6);
roadGraph.addEdge(1, 7);

roadGraph.addEdge(2, 3);
roadGraph.addEdge(2, 7);
roadGraph.addEdge(2, 8);
roadGraph.addEdge(2, 9);

roadGraph.addEdge(3, 4);
roadGraph.addEdge(3, 9);

roadGraph.addEdge(4, 9);
roadGraph.addEdge(4, 10);

roadGraph.addEdge(5, 6);
roadGraph.addEdge(5, 11);

roadGraph.addEdge(6, 7);
roadGraph.addEdge(6, 12);

roadGraph.addEdge(7, 13);

roadGraph.addEdge(8, 9);
roadGraph.addEdge(8, 13);
roadGraph.addEdge(8, 15);

roadGraph.addEdge(9, 10);
roadGraph.addEdge(9, 15);
roadGraph.addEdge(9, 16);

roadGraph.addEdge(11, 12);
roadGraph.addEdge(11, 17);

roadGraph.addEdge(12, 13);
roadGraph.addEdge(12, 17);
roadGraph.addEdge(12, 18);

roadGraph.addEdge(13, 14);
roadGraph.addEdge(13, 18);

roadGraph.addEdge(14, 15);
roadGraph.addEdge(14, 19);

roadGraph.addEdge(15, 16);
roadGraph.addEdge(15, 20);
roadGraph.addEdge(15, 21);

roadGraph.addEdge(16, 21);

roadGraph.addEdge(17, 22);
roadGraph.addEdge(17, 23);

roadGraph.addEdge(18, 19);
roadGraph.addEdge(18, 23);

roadGraph.addEdge(19, 20);
roadGraph.addEdge(19, 26);
roadGraph.addEdge(19, 27);
roadGraph.addEdge(19, 28);

roadGraph.addEdge(20, 21);
roadGraph.addEdge(20, 29);

roadGraph.addEdge(21, 30);

roadGraph.addEdge(22, 24);
roadGraph.addEdge(22, 25);

roadGraph.addEdge(23, 25);
roadGraph.addEdge(23, 26);

roadGraph.addEdge(24, 25);

roadGraph.addEdge(25, 26);
roadGraph.addEdge(25, 31);
roadGraph.addEdge(25, 32);
roadGraph.addEdge(25, 33);

roadGraph.addEdge(26, 27);
roadGraph.addEdge(26, 33);
roadGraph.addEdge(26, 34);

roadGraph.addEdge(27, 34);

roadGraph.addEdge(28, 29);
roadGraph.addEdge(28, 34);
roadGraph.addEdge(28, 35);

roadGraph.addEdge(29, 30);

roadGraph.addEdge(30, 35);

roadGraph.addEdge(31, 32);

roadGraph.addEdge(32, 33);

roadGraph.addEdge(33, 34);

export type Zone = "1" | "2" | "3" | "4";

export const allZones: Zone[] = ["1", "2", "3", "4"];

export type ZoneCount = {
  [zone in Zone]?: number;
};

export interface IntersectionZones {
  [intersection: Intersection]: ZoneCount;
}

const zones: IntersectionZones = {
  0: { "4": 1 },
  1: { "4": 1 },
  2: { "2": 1, "3": 1 },
  3: { "4": 1 },
  4: { "2": 1 },
  5: { "4": 1 },
  6: { "2": 1, "3": 1 },
  7: { "2": 1 },
  8: { "1": 1, "3": 1 },
  9: {},
  10: { "4": 1 },
  11: { "4": 1 },
  12: { "2": 1 },
  13: { "1": 2 },
  14: { "1": 2 },
  15: { "1": 1, "3": 1 },
  16: { "4": 1 },
  17: { "2": 1 },
  18: { "1": 2 },
  19: { "1": 2 },
  20: { "2": 1, "3": 1 },
  21: { "4": 1 },
  22: { "4": 1 },
  23: { "1": 1, "3": 1 },
  24: { "4": 1 },
  25: {},
  26: { "1": 1, "3": 1 },
  27: { "2": 1, "3": 1 },
  28: { "4": 1 },
  29: { "2": 1 },
  30: { "4": 1 },
  31: { "2": 1 },
  32: { "4": 1 },
  33: { "2": 1, "3": 1 },
  34: { "4": 1 },
  35: { "4": 1 },
};

export const zoneList = (zones: IntersectionZones) =>
  Object.entries(zones).map(([i, zoneInfo]) => {
    const intersection = parseInt(i);
    const z: ZoneCount = zoneInfo;
    return { intersection, zones: z };
  });

const staticBoard = { roadGraph, zones };

export default staticBoard;
