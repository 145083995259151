import { Ctx } from "boardgame.io";
import { IntersectionAction } from ".";
import { playerColor } from "..";
import { BusState, initializeIntersection } from "../../game";
import staticBoard, { Intersection, zoneList } from "../../game/staticBoard";
import { trainStation1, trainStation2 } from "./intersectionPositions";
import IntersectionUI from "./IntersectionUI";

interface Props {
  state: BusState;
  ctx: Ctx;
  intersectionAction?: IntersectionAction;
  isActive: boolean;
}

const Intersections: React.FC<Props> = (props) => {
  const { state, ctx, intersectionAction, isActive } = props;

  return (
    <>
      {zoneList(staticBoard.zones).map(({ intersection, zones }) => {
        const intersectionInfo =
          state.intersections[intersection] ?? initializeIntersection();
        const { lines, size } = getLinesThroughIntersection(
          state,
          intersection
        );
        const color =
          lines.length === 1
            ? playerColor(ctx.playOrder.indexOf(lines[0]!))
            : "black";

        const isTrainStation =
          intersection === trainStation1 || intersection === trainStation2;
        return (
          <IntersectionUI
            intersection={intersection}
            zones={zones}
            intersectionInfo={intersectionInfo}
            intersectionAction={intersectionAction}
            key={intersection}
            isActive={isActive}
            color={color}
            size={size}
            isTrainStation={isTrainStation}
            currentClock={state.clock.value}
          />
        );
      })}
    </>
  );
};

export default Intersections;

const getLinesThroughIntersection = (
  state: BusState,
  intersection: Intersection
) => {
  const neighborIntersectionCounts: { [intersection: Intersection]: number } =
    {};
  const lines = [];
  for (const [playerId, playerInfo] of Object.entries(state.playerInfo)) {
    const index = playerInfo.line.indexOf(intersection);
    if (index >= 0) {
      lines.push(playerId);
      const prev = playerInfo.line[index - 1];
      const next = playerInfo.line[index + 1];
      if (prev) {
        if (!neighborIntersectionCounts[prev]) {
          neighborIntersectionCounts[prev] = 0;
        }
        neighborIntersectionCounts[prev] += 1;
      }
      if (next) {
        if (!neighborIntersectionCounts[next]) {
          neighborIntersectionCounts[next] = 0;
        }
        neighborIntersectionCounts[next] += 1;
      }
    }
  }
  const size = Object.values(neighborIntersectionCounts).reduce(
    (a, b) => Math.max(a, b),
    0
  );
  return { lines, size };
};
