import { Ctx, MoveFn } from "boardgame.io";
import { INVALID_MOVE } from "boardgame.io//core";
import { BusState, currentPlayerInfo, getMoveKind } from ".";
import { isValidIntersection } from "./placeBuildings";
import staticBoard, { Intersection } from "./staticBoard";

export const placeLine: MoveFn<BusState> = (
  { G: state, ctx },
  lineIdArg: unknown,
  fromWhichEnd: "start" | "end"
) => {
  if (state.actionsLeft <= 0) return INVALID_MOVE;
  if (getMoveKind(state, ctx) !== "placeLine") return INVALID_MOVE;

  const line = parseLineId(lineIdArg);
  if (!line) return INVALID_MOVE;
  const lineId = getLineId(line);

  const availableLines = getAvailableLines(state, ctx);
  let allAvailableLines: LineId[] = [];
  if (availableLines !== "any") {
    allAvailableLines = availableLines.availableFromStart.concat(
      availableLines.availableFromEnd
    );
  }

  if (availableLines !== "any" && !allAvailableLines.includes(lineId)) {
    return INVALID_MOVE;
  }

  const playerInfo = currentPlayerInfo(state, ctx);

  if (availableLines === "any") {
    playerInfo.line.push(line.small);
    playerInfo.line.push(line.big);
    state.actionsLeft -= 1;
    return;
  }

  let fromStart = availableLines.availableFromStart.includes(lineId);
  let fromEnd = availableLines.availableFromEnd.includes(lineId);

  if (fromStart && fromEnd) {
    if (fromWhichEnd === "start") {
      fromEnd = false;
    } else if (fromWhichEnd === "end") {
      fromStart = false;
    } else {
      return INVALID_MOVE;
    }
  }

  if (fromStart) {
    const currentStart = playerInfo.line[0]!;
    const newStart = line.small === currentStart ? line.big : line.small;
    playerInfo.line.unshift(newStart);
    state.actionsLeft -= 1;
    return;
  } else if (fromEnd) {
    const currentEnd = playerInfo.line[playerInfo.line.length - 1]!;
    const newEnd = line.small === currentEnd ? line.big : line.small;
    playerInfo.line.push(newEnd);
    state.actionsLeft -= 1;
    return;
  }
};

export const getAvailableLines = (
  state: BusState,
  ctx: Ctx
): { availableFromStart: LineId[]; availableFromEnd: LineId[] } | "any" => {
  const playerLine = currentPlayerInfo(state, ctx).line;
  if (playerLine.length === 0) return "any";

  const playerLineSegments = getLineSegments(playerLine);

  const otherPlayerLines = ctx.playOrder
    .filter((p) => p !== ctx.currentPlayer)
    .map((p) => {
      const line = state.playerInfo[p]!.line;
      return {
        segments: getLineSegments(line),
        start: line[0],
        end: line[line.length - 1],
      };
    });

  const getAvailableLinesFromEnd = (intersection: Intersection): LineId[] => {
    const candidateLines = getLinesFromIntersection(intersection).filter(
      (l) => !playerLineSegments.includes(l)
    );
    const lines = candidateLines.filter((l) =>
      otherPlayerLines.every(({ segments, start, end }) => {
        if (start === intersection && l === segments[0]) {
          return true;
        }
        if (end === intersection && l === segments[segments.length - 1]) {
          return true;
        }
        return !segments.includes(l);
      })
    );
    return lines.length > 0 ? lines : candidateLines;
  };

  return {
    availableFromStart: getAvailableLinesFromEnd(playerLine[0]!),
    availableFromEnd: getAvailableLinesFromEnd(
      playerLine[playerLine.length - 1]!
    ),
  };
};

const getLinesFromIntersection = (intersection: Intersection): LineId[] =>
  staticBoard.roadGraph.adjacencyLists[intersection]!.map((i) =>
    getLineId(getLine(i, intersection))
  );

export type LineId = `${Intersection}-${Intersection}`;

const parseLineId = (lineId: unknown): Line | null => {
  if (typeof lineId !== "string") return null;
  const intersections = lineId.split("-");
  if (intersections.length !== 2) return null;
  let [aString, bString] = intersections;
  if (!aString || !bString) return null;
  const a = parseInt(aString);
  const b = parseInt(bString);
  if (!isValidIntersection(a) || !isValidIntersection(b)) return null;
  if (a === b) return null;
  return getLine(a, b);
};

export const getLineId = (line: Line): LineId => `${line.small}-${line.big}`;

const getLine = (a: Intersection, b: Intersection) => ({
  small: Math.min(a, b),
  big: Math.max(a, b),
});

export interface Line {
  small: Intersection;
  big: Intersection;
}

export const getLineSegments = (line: Intersection[]) => {
  const segments: LineId[] = [];
  for (var i = 0; i < line.length - 1; i++) {
    const a = line[i]!;
    const b = line[i + 1]!;
    segments.push(getLineId(getLine(a, b)));
  }
  return segments;
};
