import { Ctx, PlayerID } from "boardgame.io";
import { circleRadius, colorFromPlayerId } from "./spaces";

interface Props {
  placedAction: PlayerID | null;
  ctx: Ctx;
}

const SingleSpace: React.FC<Props> = (props) => {
  const { placedAction, ctx } = props;
  return (
    <svg width={2 * circleRadius} height={2 * circleRadius}>
      <circle
        r={circleRadius}
        cx={circleRadius}
        cy={circleRadius}
        fill={placedAction ? colorFromPlayerId(ctx, placedAction) : "gray"}
      />
      {!placedAction && (
        <circle r={3} cx={circleRadius} cy={circleRadius} fill="white" />
      )}
    </svg>
  );
};

export default SingleSpace;
