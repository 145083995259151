import { Intersection } from "../../game/staticBoard";

const intersectionPositions: {
  [intersection: Intersection]: { x: number; y: number };
} = {
  0: { x: 100, y: 650 },
  1: { x: 50, y: 350 },
  2: { x: 200, y: 250 },
  3: { x: 200, y: 150 },
  4: { x: 200, y: 50 },
  5: { x: 200, y: 550 },
  6: { x: 200, y: 450 },
  7: { x: 200, y: 350 },
  8: { x: 300, y: 250 },
  9: { x: 300, y: 150 },
  10: { x: 400, y: 50 },
  11: { x: 200, y: 650 },
  12: { x: 400, y: 550 },
  13: { x: 400, y: 450 },
  14: { x: 400, y: 350 },
  15: { x: 400, y: 250 },
  16: { x: 400, y: 150 },
  17: { x: 400, y: 700 },
  18: { x: 500, y: 450 },
  19: { x: 500, y: 350 },
  20: { x: 600, y: 250 },
  21: { x: 500, y: 150 },
  22: { x: 650, y: 700 },
  23: { x: 600, y: 500 },
  24: { x: 750, y: 700 },
  25: { x: 750, y: 600 },
  26: { x: 700, y: 500 },
  27: { x: 650, y: 400 },
  28: { x: 850, y: 350 },
  29: { x: 750, y: 250 },
  30: { x: 750, y: 150 },
  31: { x: 850, y: 700 },
  32: { x: 850, y: 600 },
  33: { x: 850, y: 500 },
  34: { x: 800, y: 400 },
  35: { x: 850, y: 250 },
};

const smallGap = 23;
const bigGap = 30;

const zonePositions: {
  [intersection: Intersection]: { x: number; y: number }[];
} = {
  0: [{ x: -smallGap, y: 0 }],
  1: [{ x: -smallGap, y: smallGap }],
  2: [
    { x: -smallGap, y: -bigGap },
    { x: bigGap, y: smallGap },
  ],
  3: [{ x: -bigGap, y: 0 }],
  4: [{ x: -smallGap, y: -smallGap }],
  5: [{ x: bigGap, y: 0 }],
  6: [
    { x: -smallGap, y: smallGap },
    { x: smallGap, y: -smallGap },
  ],
  7: [{ x: -smallGap, y: smallGap }],
  8: [
    { x: -smallGap, y: -smallGap },
    { x: smallGap, y: -smallGap },
  ],
  10: [{ x: 0, y: -bigGap }],
  11: [{ x: 0, y: bigGap }],
  12: [{ x: bigGap, y: 0 }],
  13: [
    { x: -smallGap, y: smallGap },
    { x: smallGap, y: -smallGap },
  ],
  14: [
    { x: -smallGap, y: -smallGap },
    { x: smallGap, y: smallGap },
  ],
  15: [
    { x: -smallGap, y: smallGap },
    { x: smallGap, y: smallGap },
  ],
  16: [{ x: -smallGap, y: smallGap }],
  17: [{ x: -smallGap, y: smallGap }],
  18: [
    { x: -smallGap, y: -smallGap },
    { x: 0, y: bigGap },
  ],
  19: [
    { x: -smallGap, y: -smallGap },
    { x: -smallGap, y: smallGap },
  ],
  20: [
    { x: smallGap, y: -smallGap },
    { x: smallGap, y: smallGap },
  ],
  21: [{ x: 0, y: -bigGap }],
  22: [{ x: 0, y: bigGap }],
  23: [
    { x: 0, y: -bigGap },
    { x: 0, y: bigGap },
  ],
  24: [{ x: 0, y: bigGap }],
  26: [
    { x: -smallGap, y: smallGap },
    { x: 0, y: -bigGap },
  ],
  27: [
    { x: -20, y: 20 },
    { x: 0, y: -bigGap },
  ],
  28: [{ x: bigGap, y: 0 }],
  29: [{ x: -smallGap, y: smallGap }],
  30: [{ x: -smallGap, y: smallGap }],
  31: [{ x: -smallGap, y: smallGap }],
  32: [{ x: bigGap, y: 0 }],
  33: [
    { x: smallGap, y: smallGap },
    { x: smallGap, y: -smallGap },
  ],
  34: [{ x: 0, y: -bigGap }],
  35: [{ x: smallGap, y: -smallGap }],
};

const trainStation1 = 9;
const trainStation2 = 25;

export { intersectionPositions, zonePositions, trainStation1, trainStation2 };
